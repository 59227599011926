import { Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from '../services/users.service';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  userDetails: any = [];
  walletBalance:number=0;
  exposure:number=0;
  logo: string;
  subscription: Subscription;
  loginButtnStatus:boolean=false;
  reg_symbol:boolean=true;
  @HostListener("document:visibilitychange",['$event'])
  visibilitychange(event:any) {
    if (!document.hidden){
      this.refresh();
} 
    }

  constructor(private router: Router, private usersService: UsersService,private socket: Socket,public _location: Location,private toastr: ToastrService) 
    { 
        this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
        this.usrLogOut();
        this.changeIcon();
        // this.socketConn();
    }

  ngOnInit(): void { 
    
    this.subscription =this.usersService.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
        this.walletBalance = data.balance;
        this.exposure= data.exposure;
        // this.subscription.unsubscribe();
      });
     
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
      this.loginButtnStatus=true;
    }
  }

  async findHostName()
    { 
      return (window.location.hostname);
    }

  async changeIcon() {
     const hostname=await this.findHostName();
     const splithostname= hostname.split('.')
     this.logo = splithostname[0];
    //  console.log(this.logo);
     
     if(this.logo==='paisaexch')
     {
       this.reg_symbol=false;
     }
  }

  
   getUserBalance() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.exposure= data.exposure;
          // this.socket.removeListener('get-user-success');
        }
       }).bind(this));
      
    }

    RefreshBal() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('refresh-balance', userdata);
      setTimeout(()=>{ this.toastr.success('refresh balance', 'Success!');
   },700);
    }

  usrLogOut(){
    this.socket.on('logout',(function(data:any){
      this.logoutUser();
     }).bind(this));
  }

  
  inplay() {
    this.router.navigate(['/dashboard/inplay'])
  }

  refresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this._location.path())]);
    });
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
    }
  }

  getWidth()
  {
   if(this.logo==='fairbets247')
   {
    return '50px';
   }
   else if(this.logo==='ferrariclubb')
   {
    return '70px';
   }
   else if(this.logo==='boomboombook')
   {
    return '140px';
   }
   else if(this.logo==='acepunt')
   {
    return '65px';
   }
   else
   {
    return '100px';
   }

  }

  socketConn(){
    this.socket.on('disconnect',(function(data:any){
    if(data){
      console.warn('disconnect');
    }
   }).bind(this));

    this.socket.on('connect',(function(data:any){
      alert('connect')
        console.warn('connect');
   }).bind(this)); 
  }


  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    window.location.replace('login');
  }

  ngOnDestroy() {
    this.socket.removeListener('get-user-success');
    this.socket.removeAllListeners();
  }

}
