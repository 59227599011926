<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <div style=" margin-top: 75px;color: #fff;">
            <marquee>1 point = 1 Rupees</marquee>
        </div>
        <div style="height:80vh">
            <iframe id="auracasino" class="sun" [src]="iframUrl" *ngIf="iframUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%" height="100%;" scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>