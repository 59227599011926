import { Injectable} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Subject} from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public Base_Url = environment['adminServerUrl'];
  public Bwt_Url = environment['bwtServerUrl'];
  public Bwt_staging_Url = environment['bwtstagingServerUrl'];
  private userBalanceSubjectName = new Subject<any>();
  private betStatus = new Subject<any>();
  public Aura_Url = 'https://aura.fawk.app/';
  constructor(private http: HttpClient ) 
   {
    
   }
 
  returnUserBalance(){
    return this.userBalanceSubjectName.asObservable();
  }
  returnBetStatus(){
    return this.betStatus.asObservable();
  }
  
  Get(action){
    return this.http.get(this.Base_Url+action);
   }

   Post(Base_Url:any,data:any){
    return this.http.post(Base_Url,data);
   }

   getdata(action){
    return this.http.get(this.Bwt_Url+action);
   }

   postdata(Bwt_Url:any,data:any){
    return this.http.post(this.Bwt_Url+Bwt_Url,data);
   }


   postdatastaging(Bwt_Url:any,data:any){
    return this.http.post(this.Bwt_staging_Url+Bwt_Url,data);
   }

   putdata(Bwt_Url:any,data:any){
    return this.http.put(this.Bwt_Url+Bwt_Url,data);
   }


   post_api(Base_Url:any,data:any)
   {
    return this.http.post(this.Base_Url+Base_Url,data);
   }
   
   post_api_aura(Aura_Url:any,data:any)
   {
    return this.http.post(this.Aura_Url+Aura_Url,data);
   }

   post_kapi(Base_Url:any,data:any)
   {
    return this.http.post(this.Bwt_Url+Base_Url,data);
   }


   updateUserBalanceSubject(data){
    this.userBalanceSubjectName.next(data); 
   }

   updateBetPlaced(data){
    this.betStatus.next(data); 
   }
}
