import { Component, OnInit, ViewChild, ChangeDetectorRef,TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BetSlipBottomSheetComponent } from '../bet-slip-bottom-sheet/bet-slip-bottom-sheet.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'q';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Match } from '../model/match';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from '../services/login.service';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-virtual-detail',
  templateUrl: './virtual-detail.component.html',
  styleUrls: ['./virtual-detail.component.scss']
})
export class VirtualDetailComponent implements OnInit,AfterContentChecked,OnDestroy {
  @ViewChild('accordion') accordion: MatAccordion;
  team:any={
    Team1id:'',
    Team2id:'',
    Team1name:'',
    Team2name:'',
    Team1run:'',
    Team2run:'',
    Team1wicket:'',
    Team2wicket:'',
    }
  manualMatchStatus:boolean=true;
  bookmakerStatus:string;
  netConnectService:Subscription;
  isConnected:any;
  eventId:any;
  matchoddMarket:any=[];
  matchDetail:any;
  marketName:any;
  lodTimer:number=0;
  clsInterval:any;
  betSub:any;
  internetConn:boolean=true;
  panelOpenState1: any=true;
  panelOpenState2: any;
  panelOpenState3: any;
  matchData: any;
  match_id: any;
  matchName: any;
  matchDate: any;
  isSocket: number;
  userDetails: any;
  score: boolean;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  tv: boolean;
  graph: boolean;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  matchDetailLength: any;
  callingType = 1;
  matchCalling = 1;
  mTimer:number=0;
  fancytimer:any
  fancy: any;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  perball: any;
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  matchLength: any;
  callingFancyType = 1;
  inplayStatus: any;
  teamPositionData: any;
  selectedAllBetFilter: any = 'all';
  selectedFancyBetFilter: any = 'all';
  selectedMatchBetFilter: any = 'all';
  allBetData: any;
  moment: any = moment;
  showFancyList: boolean;
  userData: {};
  firstData: any;
  homeData: any;
  modalRef: BsModalRef;
  allBetDataLength:number=0;
  matchedBetsLength: any;
  fancyBetsLength: any;
  fancyPosData: any;
  fancyLiability: any;
  betSize: any;
  selectedMarket: any
  selectedFancyMarket: any;
  marketIds: any = [];
  marketRunnerData: any;
  twenty: boolean;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  applyUserValidation:boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyIdValidation: any;
  fancyLength: any;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ringOn : boolean = false;
  param:any;
  betDataPopup: boolean;
  allPopBetData: any;
  message: string;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  subscription: Subscription;
  resultA:any=[];

  constructor(private _bottomSheet: MatBottomSheet, private http: HttpClient, private route: ActivatedRoute, public sanitizer: DomSanitizer, private router: Router, public toastr: ToastrService,
    public matchModel: Match, private modalService: BsModalService, private cdref: ChangeDetectorRef, private usersService: UsersService, public dialog: MatDialog,private loginService: LoginService,private socket: Socket,private connectionService: ConnectionService) 
    {
      this.route.paramMap.subscribe(param=>{
        this.eventId=param.get('eventId') // (+)Converts string 'id' to number
        });

        this.subscription =this.usersService.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
         this.getMyBets();
          //  this.createRoom();
        // this.subscription.unsubscribe();
      });

      this.betSub =this.usersService.returnBetStatus().subscribe
      (data => { //message contains the data sent from service
        this.lodTimer=2;
        this.setIntLod();
        // this.betSub.unsubscribe();
        
      });

      this.checkIntConn();
     }

  ngOnInit(): void {
    this.createRoom();
  }

  checkIntConn(){

    // checkInternetConnection
    this.netConnectService=this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.internetConn=true; 
      }
      else {
        this.internetConn=false;
      }
    });
    // console.warn('checkConn');
    
    // this.socket.on('disconnect',(function(data:any){
    //   if(data){
    //     console.warn('disconnect');
    //     this.internetConn=false;
    //   }
    //  }).bind(this));

    //   this.socket.on('connect',(function(data:any){
    //       console.warn('connect');
    //       this.internetConn=true;  
    //  }).bind(this)); 
  }

  setIntLod(){
    this.clsInterval=setInterval(()=> { this.decValue(); }, 1000);
  }

  decValue(){
    this.lodTimer--;
    if(this.lodTimer==0){
      clearInterval(this.clsInterval);  
    }
  }

  
  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
  
  }

  async createRoom(){ 
    this.userDetails=await this.getDetials();
    const data = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };
    
    this.socket.emit('add-to-room-virtual', data);
    // console.warn(this.eventId);
    this.socket.on('virtual-pulse-'+this.eventId, (function(data:any){
      // console.warn(data);
       this.matchoddMarket=[];
       this.matchDetail='';
       this.team.Team1run=0;
       this.team.Team2run=0;
       this.team.Team1wicket=0;
       this.team.Team2wicket=0;
       const eventData=data;
       this.matchDetail=data;
       this.matchoddMarket.push(this.matchDetail); 
       //team 
       this.team.Team1id=this.matchDetail.Team1id;
       this.team.Team2id=this.matchDetail.Team2id;
       this.team.Team1name=this.matchDetail.Team1name;
       this.team.Team2name=this.matchDetail.Team2name;

      //  this.team.Team1wicket=this.matchDetail.Team1wicket;
      //  this.team.Team2wicket=this.matchDetail.Team2wicket;
      
       for(var i=0;i<this.matchDetail.scoreHomeVirtual.length;i++)
       {
        this.team.Team1run += Number(this.matchDetail.scoreHomeVirtual[i].Run);
        this.team.Team1wicket += Number(this.matchDetail.scoreHomeVirtual[i].Wkt);
       }
      //  console.warn(this.team.Team1run);
       
       for(var i=0;i<this.matchDetail.scoreAwayVirtual.length;i++)
       {
        this.team.Team2run += Number(this.matchDetail.scoreAwayVirtual[i].Run);
        this.team.Team2wicket += Number(this.matchDetail.scoreAwayVirtual[i].Wkt);
       }
      //  console.warn(this.team.Team2run);

        // check STatus
        if(this.matchoddMarket.marketTypeStatus===0)
        {
          this.manualMatchStatus=false;
        }else if(this.matchoddMarket.marketTypeStatus==="undefined")
        {
          this.manualMatchStatus=true;
        }
        
      this.marketName=this.matchDetail.marketName;
      this.matchName=this.matchDetail.eventName;
      this.matchDate=this.matchDetail.openDate;
      // timer
      if (this.matchDetail.timers <= 180) {
        this.mTimer = 0;
      } else {
        this.mTimer = (this.matchDetail.timers) - 180;
      }
      
     }).bind(this));

    //  resultListner
    const data1={
      user: {
      _id: this.userDetails._id,
      key: this.userDetails.key,
     details: {
       username: this.userDetails.details.username,
       role: this.userDetails.details.role,
       status: this.userDetails.details.status,
     }
    },
    eventTypeId:'v9'
  }
    this.socket.emit('get-virtual-result', data1);
    this.socket.on('get-virtual-result-success', (function(data:any){
      // console.warn(data);
      this.resultA=data;
     }).bind(this));

     this.getMyBets();
  }

  
  checkMatchOddStatus(matchOddsData:any):boolean{
    // console.warn(matchOddsData);
    if(matchOddsData.marketBook.status ==='OPEN'&& matchOddsData.managerStatus[this.userDetails.details.manager] === true)
    {
      return false;
    }
    else{
      return true;
    }
    
  }

  checkWinTossStatus(matchOddsData:any):boolean{

    if(matchOddsData?.marketBook?.status ==='OPEN')
    {
      return false;
    }
    else{
      return true;
    }
    
  }

  checkBookmakerStatus(matchOddsData:any,runner:any):boolean{
    if(matchOddsData.marketBook.status ==='OPEN' && runner.status === 'ACTIVE' &&
    matchOddsData.managerStatus[this.userDetails.details.manager] === true)
    {
      return false;
    }
    else{
      return true;
    }
    
  }


  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  
  getOddsValue(price,selectionId,marketid,marketName,eventName,type,runnerName) {

    this.betslipinfo = true;
    this.betPrice = price;
  
    const betdataS = {
      user: {
         _id: this.userDetails._id,
         key: this.userDetails.key,
         details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
          manager: this.userDetails.details.manager,
          master: this.userDetails.details.master,
          subadmin: this.userDetails.details.subadmin,
          image: this.userDetails.details.image,
          admin: this.userDetails.details.admin,
          balance: this.userDetails.details.balance,
          exposure: this.userDetails.details.exposure,
        }
      },
      bet: {
        runnerId: selectionId,
        selectionName: runnerName,
        rate: price,
        stake: 0,
        marketId: marketid,
        marketName: marketName,
        eventId: this.eventId,
        eventName: eventName,
        type:type,
      }
    };
    
    // betBottemShetOpenford
    this.arrayObj = {
      selection_id: selectionId,
      market_id: marketid,
      odds: price,
      stake: 0,
      is_back: type,
      is_fancy: 0,
      MatchName: marketName,
      placeName: '',
      isManual: 0,
      is_session_fancy: 'N'
    };

    // localStorage.setItem('type',type);
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    sessionStorage.setItem('betDataS', JSON.stringify(betdataS));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }
  setSessionValue(price,marketid,marketName,eventName,type,size) {
    this.betslipinfo = true;
    this.betPrice = price;
    this.betSize = size;
    
    // this.fancyIdValidation = FancyObject.fancy_id;

    let newRate;
    if ( size === 0) {
      newRate = 1;
    }
    else {
      newRate = size / 100;
    }
    
   const sessionBet= {
    user: {
      _id: this.userDetails._id,
      key: this.userDetails.key,
      details: {
       username: this.userDetails.details.username,
       role: this.userDetails.details.role,
       status: this.userDetails.details.status,
       manager: this.userDetails.details.manager,
       master: this.userDetails.details.master,
       subadmin: this.userDetails.details.subadmin,
       image: this.userDetails.details.image,
       admin: this.userDetails.details.admin,
       balance: this.userDetails.details.balance,
       exposure: this.userDetails.details.exposure,
     }
   },
    bet: {
      runnerId: 1,
      selectionName: price,
      rate: newRate,
      stake: 0,
      marketId: marketid,
      marketName: marketName,
      eventId: this.eventId,
      eventName: eventName,
      type: type,
    }
      
    }
 

    // betBottemShetOpenford
    this.arrayObj = {
      fancy_id: '',
      market_id: marketid,
      odds: price,
      stake: 0,
      is_fancy: 1,
      is_back: type,
      MatchName:'',
      placeName: '',
      isManual: 0,
      size: size,
      is_session_fancy: 'Y'
    };

    this.matchModel.isbetslipshow = true;
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    sessionStorage.setItem('betSessionData', JSON.stringify(sessionBet));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  getBetSlipDataForOdds() {

    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    // this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    // if (this.IsToggle) {
    //   this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
    //   this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    //   this.matchModel.loading1 = true;
    //   // this.Place_Order(this.matchModel.tempArray);
    // }
    this.openBetSlipBottomSheet(this.matchModel, this.arrayObj)
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn);

    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;

      }
      this.matchModel.calculateProfitLoss(back);
      if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.priceVal > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.priceVal * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }

      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }
  calculateP_lOnStack(stake, stkbtn, isback, back) {

    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }
 
  searchRunner(runners: any[], id: string): any { 
    if(!runners) return null;
    for (var key in runners) {
      if(runners[key].selectionId == id) 
      return runners[key].runnerName;
    }
    
  }

  searchRunnerse(runners: any[], id: string): any {
    if(!runners) return null;
    for (var key in runners) {
      if(runners[key].selectionId == id) 
      return runners[key].runnerName;
    }
    
  }

  scoreApi() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.http.get('https://ex.7dayexch.biz/api/v2/getLiveScore?id=' + this.matchData.match_id).subscribe((data) => {
      if (data['status'] == '1') {
        if (data["result"]["type"] == "up" || data["result"]["type"] == "auto") {

        } else {
          this.score = true;
          this.scoreData = data["result"]["home"];
          this.scoreLength = Object.keys(this.scoreData).length;
          if ((this.scoreLength) <= 31) {
            this.twenty = true;
            this.scoreBoard = {
              "b1s": (this.scoreData.b1s).split(','),
              "b2s": (this.scoreData.b2s).split(','),
              "bw": this.scoreData.bw,
              "i": this.scoreData.i,
              "i1": {
                "ov": this.scoreData.i1.ov,
                "sc": this.scoreData.i1.sc,
                "wk": this.scoreData.i1.wk
              },
              "i1b": this.scoreData.i1b,
              "i2": {
                "ov": this.scoreData.i2.ov,
                "sc": this.scoreData.i2.sc,
                "tr": this.scoreData.i2.tr,
                "wk": this.scoreData.i2.wk
              },
              "iov": this.scoreData.iov,
              "lw": this.scoreData.lw,
              "p1": this.scoreData.p1,
              "p2": this.scoreData.p2,
              "pb": (this.scoreData.pb).split(','),
              "pt": (this.scoreData.pt).split(','),
              "t1": {
                "f": this.scoreData.t1.f,
                "ic": this.scoreData.t1.ic,
                "n": this.scoreData.t1.n
              },
              "t2": {
                "f": this.scoreData.t2.f,
                "ic": this.scoreData.t2.ic,
                "n": this.scoreData.t2.n
              }
            }
            this.perball = (this.scoreBoard.pb).slice(1).slice(-6);
          } else {
            this.twenty = false;
            this.scoreBoard = {
              "b1s": (this.scoreData.b1s).split(','),
              "b2s": (this.scoreData.b2s).split(','),
              "bw": this.scoreData.bw,
              "i": this.scoreData.i,
              "i1": {
                "ov": this.scoreData.i1.ov,
                "sc": this.scoreData.i1.sc,
                "wk": this.scoreData.i1.wk
              },
              "i1b": this.scoreData.i1b,
              "i3b": this.scoreData.i3b,
              "i2": {
                "ov": this.scoreData.i2.ov,
                "sc": this.scoreData.i2.sc,
                "tr": this.scoreData.i2.tr,
                "wk": this.scoreData.i2.wk
              },
              "i3": {
                "ov": this.scoreData.i3.ov,
                "sc": this.scoreData.i3.sc,
                "tr": this.scoreData.i3.tr,
                "wk": this.scoreData.i3.wk
              },
              "i4": {
                "ov": this.scoreData.i4.ov,
                "sc": this.scoreData.i4.sc,
                "tr": this.scoreData.i4.tr,
                "wk": this.scoreData.i4.wk
              },
              "iov": this.scoreData.iov,
              "lw": this.scoreData.lw,
              "p1": this.scoreData.p1,
              "p2": this.scoreData.p2,
              "pb": (this.scoreData.pb).split(','),
              "pt": (this.scoreData.pt).split(','),
              "t1": {
                "f": this.scoreData.t1.f,
                "ic": this.scoreData.t1.ic,
                "n": this.scoreData.t1.n
              },
              "t2": {
                "f": this.scoreData.t2.f,
                "ic": this.scoreData.t2.ic,
                "n": this.scoreData.t2.n
              }
            }

            this.perball = (this.scoreBoard.pb).slice(1).slice(-6);
          }

        }
      }
    }, (err) => {
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          // this.timer = setTimeout(() => resolve(this.scoreApi()), 3000);
        }
      })
  }
  betslipStatus: boolean = false;
  hideClose() {
    this.betslipStatus = false;
  }
  activeBetslip() {
    this.betslipStatus = true;
  }
  liveTVStatus: boolean = true;
  openTvDiv() {
    this.liveTVStatus = !this.liveTVStatus;
  }
  liveScoreStatus: boolean = false;
  openScoreDiv() {
    this.liveScoreStatus = !this.liveScoreStatus;
  }
  back() {
    window.history.back();
  }
  openModalBets(bets: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      bets,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
    this.filterallbetBasedRadioSelect(event, 'all')
  }
  
  openBetSlipBottomSheet(data, arrayObj): void {
    const BetSlipbottomSheetRef = this._bottomSheet.open(BetSlipBottomSheetComponent, {
      data: { matchModel: this.matchModel, arrayObj: this.arrayObj, betPrice: this.betPrice, betData: this.betData, betSize: this.betSize,
        applyUserValidation:this.applyUserValidation, sportsSettingValues:this.sportsSettingValues,checkEventLimit : this.check_event_limit,fancyId : this.fancyIdValidation,
       session_setting : this.sessionSetting,ring_On:this.ringOn},
    });

    // BetSlipbottomSheetRef.afterDismissed().subscribe(() => {
    //   this.getFancyLiability()
    // });
  }

  getMyBets() {
    this.allBetData=[];
    const getBet = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        eventId: this.eventId,
        username: this.userDetails.details.username,
        deleted: false,
        result: 'ACTIVE',
      },
      sort: {placedTime: -1},
    };
    
    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function(data:any){ 
      this.allBetData=data;
      this.allBetDataLength = this.allBetData.length;
     }).bind(this));
  
  }

  calProLoss(a:any,data:any,index:number,matchOddsData:any){

    if(a&&this.allBetData)
    {
      let test = this.allBetData.filter(item => {
        return item.marketName == matchOddsData.marketName;
      });

     let betsValue = test.filter(item => {
      if (a[index].runnerName != undefined) {
        return item.selectionName == a[index].runnerName;
      } else {
        return item.selectionName == data.runners[index].runnerName;
      }
    });

     let laystaketotal = test.filter(item => {
      if (a[index].runnerName != undefined) {
        return item.selectionName != a[index].runnerName;
      } else {
        return item.selectionName != data.runners[index].runnerName;
      }
    });
 
  let backData = betsValue.filter(item => {
   return item.type == 'Back';
  });

  let layData = betsValue.filter(item => {
  return item.type == 'Lay';
  });

  let oppBack = laystaketotal.filter(item => {
  return item.type == 'Back';
  });

  let totalOppBack = 0;
  oppBack.map(b => {
  totalOppBack = totalOppBack + b.stake;
  });

  let oppLay = laystaketotal.filter(item => {
   return item.type == 'Lay';
  });

  let totalOppLay = 0;
  oppLay.map(b => {
  totalOppLay = totalOppLay + b.stake;
  });


  let backvalue = 0;
  backData.map(b => {
  let back = b.stake * (b.rate - 1);
  backvalue = backvalue + back;
  });

  let layvalue = 0;
  layData.map(b => {
  let lay = b.stake * (b.rate - 1);
  layvalue = layvalue + lay;
  });

  let backtotal = backvalue - totalOppBack;
  let laytotal = totalOppLay - layvalue;

  let markettotal;
  //  if (market === true) 
  //  {
  //   let totalno = backtotal + laytotal;
  //   markettotal = totalno * 37;
  //  } 
  //  else 
  //  {
  //   markettotal = backtotal + laytotal;
  //  }

  markettotal = backtotal + laytotal;

  return (markettotal);
    }

  }
  

  volumeOn(i){
    if(i == 0){
      this.ringOn = false;
    } else {
      this.ringOn = true;
    }
  }

  filterallbetBasedRadioSelect(event, filterName) {
    this.allBetData = this.betData
    if (filterName == 'all') {
      this.allBetData = this.betData
    } else {
      this.allBetData = this.betData.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }

  filterFancybetBasedRadioSelect(event, filterName) {
    this.fancyBets = this.betData.filter(t => t.is_fancy == '1');
    if (filterName == 'all') {
      this.fancyBets = this.betData.filter(t => t.is_fancy == '1');
    } else {
      this.fancyBets = this.fancyBets.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }
  filterMatchbetBasedRadioSelect(event, filterName) {
    this.matchedBets = this.betData.filter(t => t.is_fancy == '0');
    if (filterName == 'all') {
      this.matchedBets = this.betData.filter(t => t.is_fancy == '0');
    } else {
      this.matchedBets = this.matchedBets.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }
  openModalCondition(Terms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      Terms,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }
 
  addOtherMarket(data) {
    localStorage.setItem('matchData', JSON.stringify(data));
    if (this.router.url.split('?')[0] == '/match-detail') {
      window.location.reload();
    } else {
      this.router.navigate(['match-detail']);
    }
  }
 
  itemTrackBy(index: number, item) {
    return index;
  }

  virtualSocketRemove()
  {
    let newremovedata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      eventId: "1234822733",
    };
  
    this.socket.emit('remove-from-room-virtual', newremovedata);
  }

  ngOnDestroy():void{
    this.netConnectService.unsubscribe();
    // console.warn('ngOnDestroy');
    
    this.virtualSocketRemove();
    this.socket.removeAllListeners('');
  }
  
}
