import { Component, OnInit, ViewChild, ChangeDetectorRef,TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit,OnDestroy {
  userDetails:any;
  ledgerList:any;
  days:number=1;
  modalRef:any;
  referLog:any;
  constructor( private modalService: BsModalService,private socket: Socket,private toastr: ToastrService) { }
  
  ngOnInit(): void {
    this.storeData();
  }

  async getDetials(){
    try {
      const data=await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }
    
  }

  async storeData()
  {
    this.userDetails=await this.getDetials();
    this.getLedgerSoc(this.days);
  }

    getLedgerSoc(value:number)
    {

      const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        username: this.userDetails.details.username,
        action: {$in: ['BALANCE', 'AMOUNT', , 'COMMISION']},
        deleted: false,
        time: {
          $gte: new Date(new Date().getTime() - (value * 24 * 60 * 60 * 1000)),
        },
      },
      sort: {time: -1},
    };
  
    this.socket.emit('get-logs', userdata);

    this.socket.on('get-logs-success', (function(data:any){
      if(data){ 
        // console.warn(data);
        
        this.toastr.success(data.message, 'Success!');
        this.ledgerList=data;
        this.removeListner();
      }
          
     }).bind(this));
    
  }

  onValChange(value)
  {
    this.getLedgerSoc(value);
  }

  removeListner()
  {
     this.socket.removeListener('get-logs-success');
  }

  refer_log(ledgerBook: TemplateRef<any>,x:any)
  {
    this.referLog=x;
    this.modalRef = this.modalService.show(
      ledgerBook,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
  }

  ngOnDestroy() {
    this.socket.removeAllListeners();
  }

}
