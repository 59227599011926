<div class="mobile-bottom-bar">
  <div class="bottom-menu bottom-menu contianermobile">
    <mat-list>
      <mat-list-item *ngIf="userDetails?.details?.manager!='OSG365'" [routerLink]="['/dashboard/inplay']"
        [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
        <i class="material-icons">ondemand_video</i>
        <span>Inplay</span></mat-list-item>
      <mat-list-item [routerLink]="['/dashboard/']" [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active']">
        <i class="material-icons">home</i> <span>Dashboard</span></mat-list-item>

      <mat-list-item *ngIf="userDetails?.details?.manager!='OSG365' && aurastatus=='true'" [routerLink]="['/casinoaura']"
        [routerLinkActive]="['active']">
        <i class="material-icons">casino</i> <span>Aura Casino</span>
      </mat-list-item>
      <mat-list-item *ngIf="userDetails?.details?.manager!='OSG365' && qtechstatus=='true'" [routerLink]="['/casino']"
        [routerLinkActive]="['active']">
        <i class="material-icons">casino</i> <span>Casino</span>
      </mat-list-item>

      <!-- <mat-list-item
        *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'"
        class="blink_me" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
        [routerLinkActive]="['active']">
        <i class="material-icons">spoke</i> <span>Investment</span></mat-list-item> -->

      <mat-list-item [routerLink]="['/wallet']" [routerLinkActive]="['active']">
        <i class="material-icons">account_balance_wallet</i>
        <span>Wallet</span></mat-list-item>

      <mat-list-item (click)="sidenavRight.toggle()" [routerLinkActive]="['active']">
        <i class="material-icons">person</i><span>User</span></mat-list-item>
    </mat-list>
  </div>
</div>

<mat-sidenav mode="side" #sidenavRight closed position="end" (click)="sidenavRight.toggle()" class="right-panel">
  <!-- <div class="sidebar-first-heading">
        <span><mat-icon>close</mat-icon></span>
        <button mat-stroked-button color="warn" (click)="logoutUser()">Logout <mat-icon>logout</mat-icon></button>
    </div> -->
  <div class="row user-profile align-items-center">
    <div class="col-xs-3">
      <img src="assets/images/user-circle.svg" />
    </div>
    <div class="col-xs-3 username">
      {{ userDetails?.details?.username }}
      <div class="last-login">
        <div>MobileNo : </div>&nbsp;
                <div>{{userDetails?.details?.mobile}}</div>
        <!-- <div>Last login</div>
                <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
      </div>
    </div>
    <div class="sidebar-first-heading">
      <button *ngIf="tokenStatus" mat-stroked-button color="warn" (click)="logoutUser()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
  <div class="row" style="margin: 5px 10px">
    <div class="winning-card">
      <a style="color: #ffffff">
        <img src="assets/icons/account_balance.png" />
        <div>Balance</div>
        <h5>
          <b>{{ walletBalance | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365'">
      <a style="color: #ffffff">
        <i class="material-icons">casino</i>
        <!-- <img src="assets/icons/events.png"> -->
        <div>Casino</div>
        <h5>
          <b>{{ casinoBal | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365' && rollingBalance?.rolling_status==1">
      <a style="color: #ffffff">
        <i class="material-icons md-dark">credit_card</i>
        <!-- <img src="assets/icons/events.png"> -->
        <div>Rolling</div>
        <h5>
          <b>{{ rollingBalance?.rolling_amount | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <div class="exposure-card">
      <img src="assets/icons/exposure.png" />
      <div>Exposure</div>
      <h5>
        <b>{{ exposure | number : "0.0-2" }}</b>
      </h5>
    </div>
  </div>



  <div class="report-menu">
    <!-- <h4>Reports</h4> -->
    <ul class="menu-list">
      <li>
        <a class="dropdown-item" [routerLink]="['/dashboard']"><img src="assets/images/icon/home.png" /> Home</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/ledger']"><img src="assets/images/icon/open_bet.png" /> Ledger</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/offer-ledger']"><img src="assets/images/icon/open_bet.png" />Offer Ledger</a>
      </li>
      <li *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
        [routerLinkActive]="['active']">
        <i class="material-icons">spoke</i>&nbsp; Investment </a></li>


      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/casino-wallet']"><img src="assets/images/wallet.svg" /> Casino
          Wallet</a>
      </li>
      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/referAcc']"><img src="assets/images/wallet.svg" /> Refer Account</a>
      </li>
      <li *ngIf="userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/referal-report']"><img src="assets/images/wallet.svg" /> Referal
          Report</a>
      </li>
      <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/fixed-deposit']"><img src="assets/images/wallet.svg" /> Fixed
          Deposit</a>
      </li>
      <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
        <a class="dropdown-item" [routerLink]="['/fixed-deposit-report']"><img src="assets/images/wallet.svg" /> Fixed
          Deposit Report</a>
      </li>
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/referAcc']"
          ><img src="assets/images/wallet.svg" /> Refer Account</a
        >
      </li> -->
      <li>
        <a class="dropdown-item" [routerLink]="['/profile']"><img src="assets/images/icon/user.svg" /> Profile</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/terms-condition']"><img src="assets/images/icon/report.svg" /> Terms &
          Conditions</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="openModalResetPwd(changePwd)"><img
            src="assets/images/icon/change_stack.png" /> Change Password</a>
      </li>
      <li *ngIf="tokenStatus" (click)="logoutUser()">
        <a style="color: red !important"> <mat-icon>logout</mat-icon></a> LogOut
      </li>
    </ul>
  </div>
</mat-sidenav>

<ng-template #changePwd>
  <div matDialogTitle>
    <h4 class="modal-title">Change Password</h4>
  </div>
  <form [formGroup]="myForms" (ngSubmit)='onSubmitChangePassword()'>
    <mat-dialog-content>
      <div class="body-inner change-pwd">
        <div class="form-group myform">
          <label class="form-label">New Password</label>
          <input [type]="hide ? 'password' : 'text'" name="chngePass" class="form-control" formControlName="password"
            required />
        </div>
      </div>
      <div class="errors"
        *ngIf="myForms.get('password').invalid && (myForms.get('password').touched || myForms.get('password').dirty)">

        <div *ngIf="myForms.get('password').hasError('required')">
          password is required
        </div>
        <div *ngIf="myForms.get('password').errors.minlength">
          Password must be at least 8 characters
        </div>
        <div class="error-text" *ngIf="myForms.get('password').hasError('passwordStrength')">
          {{myForms.get('password').errors['passwordStrength']}}
        </div>
      </div>
      <div class="checkbox">
        <label>
          <input (change)="hide = !hide" type="checkbox" name="checkbox" /><span> show password</span>
        </label>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="text-end">
        <a data-dismiss="modal" (click)="modalRef.hide()" mat-raised-button class="btn btn-danger">Cancel</a>
        &nbsp;&nbsp;
        <!-- <button (click)="onSubmitChangePassword()" mat-raised-button class="btn btn-success" [disabled]="submitted">
          Submit
        </button> -->
        <button mat-raised-button class="btn btn-success" [disabled]="!myForms.valid">Submit</button>
      </div>
    </mat-dialog-actions>
  </form>

  <!-- <form [formGroup]="myForms" (ngSubmit)='passwordchange()'>

    <label>Password :
      <input type="text" name="PwdHtm" formControlName="password">
    </label><br>

    <div class="errors"
      *ngIf="myForms.get('password').invalid && (myForms.get('password').touched || myForms.get('password').dirty)">

      <div *ngIf="myForms.get('password').hasError('required')">
        password is required
      </div>

      <div *ngIf="myForms.get('password').errors.minlength">
        Password must be at least 8 characters
      </div>

      <div class="error-text" *ngIf="myForms.get('password').hasError('passwordStrength')">
        {{myForms.get('password').errors['passwordStrength']}}
      </div>
    </div>

    <button mat-raised-button class="btn btn-success" [disabled]="!myForms.valid">Submit</button>
  </form> -->
</ng-template>