import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-wallet-withdraw',
  templateUrl: './wallet-withdraw.component.html',
  styleUrls: ['./wallet-withdraw.component.scss']
})
export class WalletWithdrawComponent implements OnInit {
  withdrawForm: FormGroup;
  idwithdrawForm: FormGroup;
  loginButtonDisable = false;
  withdrawmethod: any;
  token: any;
  filter: any;
  type: any;
  withamount: any;
  setamount: any;
  createid: any;
  userdata: any;
  depositdata: any;
  userdatak: any;
  userwalletBalance: any;
  min_amount: any = 500;
  modalRef: BsModalRef;
  constructor(
    private _location: Location,
    private usersService: UsersService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private socket: Socket,
  ) {
    this.route.paramMap.subscribe(param => {
      this.type = param.get('type') // (+)Converts string 'id' to number
    });
  }

  ngOnInit(): void {
    if (this.type == 'idwithdraw') {
      var getamount = sessionStorage.getItem('withdrawamount');
      this.withamount = JSON.parse(getamount);
      // console.log(this.withamount);
      this.setamount = this.withamount.coins;
      var data = sessionStorage.getItem('details');
      this.createid = JSON.parse(data);
    }
    this.token = sessionStorage.getItem("token")
    var userdata = sessionStorage.getItem('wallet-user');
    this.userdata = JSON.parse(userdata);
    this.userdatak = JSON.parse(sessionStorage.getItem('userDetails'));
    // this.userdatak = user.details;
    // console.log(this.userdatak)
    // if (this.userdatak.details.manager == "FERRARICLUB") {
    //   this.min_amount = 1000;
    // }
    // console.log(this.userdata);
    this.createFrom();
    this.createidFrom();
    this.getwithdral();
    this.getUserBalance();
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }

  getUserBalance() {
    const userdata = {
      user: {
        _id: this.userdatak._id,
        key: this.userdatak.key,
        token: this.userdatak.verifytoken,
        details: {
          username: this.userdatak.details.username,
          role: this.userdatak.details.role,
          status: this.userdatak.details.status,
        },
      }
    };

    this.socket.emit('get-user', userdata);

    this.socket.on('get-user-success', (function (data: any) {
      if (data) {
        this.userwalletBalance = data.balance;
        this.userwalletexposer = data.exposure;
        // console.log( this.userwalletexposer);

      }
    }).bind(this));

  }

  getwithdral() {
    this.usersService.getdata("getPrefferedWithdrawl/" + this.token).subscribe((response: any) => {
      console.log(response);
      this.withdrawmethod = response.doc;
    })
  }
  radioChange(event, bank) {
    this.filter = bank;
    // console.log(this.filter);
  }

  openmodel(success: TemplateRef<any>) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      success,
      Object.assign({}, { class: 'success modal-lg' })
    );
  }

  createFrom() {
    this.withdrawForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(this.min_amount)]],
      type: ['', [Validators.required]],
    })
  }
  createidFrom() {
    this.idwithdrawForm = this.fb.group({
      type: ['', [Validators.required]],
    })
  }
  withdrawamount() {
    if (!this.withdrawForm.value.amount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (!this.withdrawForm.value.type) {
      this.toastr.error("Please select Bank");
      return;
    } else {
      var method = this.withdrawmethod;
      if (method) {
        for (let i = 0; i < method.length; i++) {
          if (method[i].type == this.withdrawForm.value.type) {
            this.min_amount = method[i].minLimit;
          }
        }
        if (this.withdrawForm.value.amount < this.min_amount) {
          this.toastr.error(this.withdrawForm.value.type + " amount can't be less " + this.min_amount);
          return;
        }
      }
    // }  else {
      this.loginButtonDisable = true;
      var data = {
        amount: this.withdrawForm.value.amount,
        type: this.withdrawForm.value.type,
        managerId: this.userdata.typeId,
        managertype: this.userdata.type,
        paymentId: this.filter._id,
      }
      this.usersService.postdata("withdrawalPayment/" + this.token, data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.withdrawForm?.reset();
          this.loginButtonDisable = false;
          // this.toastr.success(response.message);
        }
      });
    }
  }

  idwithdrawamount() {
    // if (this.setamount != '') {
    // } else {
    //   this.loginButtonDisable = false;
    // }
    if (!this.setamount) {
      this.toastr.error("Please Enter Amount");
      return;
    } else if (!this.idwithdrawForm.value.type) {
      this.toastr.error("Please select Bank");
      return;
    } else {
      this.loginButtonDisable = true;
      var data = {
        amount: this.setamount,
        type: this.idwithdrawForm.value.type,
        managerId: this.createid.sites._id,
        managertype: this.createid.type,
        mysiteId: this.createid._id,
        paymentId: this.filter._id,
      }
      this.usersService.postdata("withdrawalInsites/" + this.token, data).subscribe((response: any) => {
        this.depositdata = response;
        if (response) {
          document.getElementById("openModalButton").click();
          this.idwithdrawForm?.reset();
          this.loginButtonDisable = false;
          // this.toastr.success(response.message);
        }
      });
    }
  }
  godetail() {
    this.router.navigate(['wallet-withdraw-details']);
  }
  // ngOnDestroy() {
  //   sessionStorage.removeItem('withdrawamount');
  // }
}
