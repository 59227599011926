<div class="loginPage" style="background-repeat: no-repeat;background-position:center center;" [ngStyle]="logo==='clublucky7' ?{'background':'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/assets/background/LUCKY7Banner.jpg)','background-size': 'auto 110%'} : {'background':'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(/assets/background/sports.jpg)','background-size': 'cover'}">
    <div class="login-main">
        <div class="login-inner">           
            <div *ngIf="logo!='clublucky7'" class="logo text-center">
                <img src="assets/logo/{{logo}}.png" alt="img">
            </div>
            <!-- register -->
            <div *ngIf="step===1" class="form">
                <form class="login-form" [formGroup]='loginForm' (ngSubmit) ='onLoginClick()'>
            
                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="text" class="form-control" formControlName='phone'>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                    </div>
                   
                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="loginButtonDisable"><span style="font-size:17px;">LOGIN NOW </span><mat-icon matSuffix class="material-icons">login</mat-icon></button>
                    </div>
                    
                </form>
                
            </div>
             <!-- verifyOtp -->  
             <div *ngIf="step===2" class="form">
                <form class="login-form"  [formGroup]= 'varifyOtpForm' (ngSubmit) ='onVerifyOtpClick()'>
                    
                    <div class="form-group" appearance="fill">
                        <label class="form-label">Mobile No.</label>
                        <input type="tel" class="form-control" formControlName='phone' disabled>
                        <mat-icon matSuffix>mobile_friendly</mat-icon>
                        </div>

                    <div class="form-group" style="margin-bottom:10px !important;" appearance="fill">
                        <label class="form-label">OTP</label>
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName='otp'>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="showPassword" (click)="showPassword = !showPassword">visibility</mat-icon>
                        <mat-icon style="cursor:pointer;" matSuffix [class.hide]="!showPassword" (click)="showPassword = !showPassword">visibility_off</mat-icon>
                    </div>

                    
                    <button class="setrest" [disabled]="resendButtonDisable" (click)="resendotp()">Resend Otp</button>

                    <div class="submit-btn">
                        <button mat-raised-button color="primary" [disabled]="otpButtonDisable"><span style="font-size:17px;">Verify OTP </span><mat-icon matSuffix class="material-icons">how_to_reg</mat-icon></button>
                    </div>
                    
                </form>
                
            </div> 



            
        </div>
        
    </div>
    
</div>