<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>

    <div class="main-card">  
        <h3 *ngIf="matchoddMarket?.length==0 && toWinTossMarket?.length==0 && bookmakerMarket?.length==0 && sessionMarket?.length==0" class="text-warning text-center" style="margin-top:40vh;">No Active Bets !</h3>
      <!-- content -->
      <mat-card fxLayout="column" class="detail-card">

    <!-- matchodds -->
    <ng-container *ngIf="matchoddMarket?.length>0">
        <p id="sep_line"><span>Match Odds</span></p>
    <mat-accordion class="tab-body odd-detail" multi>
      <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
          *ngFor="let market of matchoddMarket;trackBy: itemTrackBy;let mDTIndex=index;" (click)="matchDetail(market.eventId)"
          (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
              <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                  <div fxLayout="row">
                      
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                          <span class="market-title">
                              <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                              <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                              {{market.eventName}}
                          </span>
                      </div>
                  </div>
                  
              </mat-panel-title>
              
          <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
              *ngFor="let runner of market.marketBook.runners; let i = index;">
              <label fxLayoutAlign="start left" fxFlex="70%"
                  style="padding-left: 10px;">
                  <span>{{searchRunner(market?.runners,runner?.selectionId)}}</span>
                 
                  <span class="text-bold" [ngClass]="{'red':calProLoss(market.runners,market.marketBook,i,market)<0,'green':calProLoss(market.runners,market.marketBook,i,market)>=0}">
                      {{calProLoss(market?.runners,market?.marketBook,i,market)| number : '0.0-2'}}
                  </span>
              </label>
              <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                  fxFlex="17%" *ngIf="runner?.availableToBack">
                  <span class="odds-value">{{(runner?.availableToBack
                      !=undefined?runner?.availableToBack?.price :'')}}</span>
                  <span class="odds-small-value">{{(runner?.availableToBack
                      !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                  fxFlex="17%" *ngIf="!runner?.availableToBack">
                  <span class="odds-value">-</span>
                  <span class="odds-small-value">-</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                  fxFlex="17%" *ngIf="runner?.availableToLay">
                  <span class="odds-value">{{(runner?.availableToLay
                      !=undefined?runner?.availableToLay?.price :'')}}</span>
                  <span class="odds-small-value">{{(runner?.availableToLay
                      !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                  fxFlex="17%" *ngIf="!runner?.availableToLay">
                  <span class="odds-value">-</span>
                  <span class="odds-small-value">-</span>
              </button>

          </div>
          
      </mat-expansion-panel>

  </mat-accordion>
</ng-container>

  <!-- toWinToss -->
  <ng-container *ngIf="toWinTossMarket?.length>0">
    <p id="sep_line"><span>To Win Toss</span></p>
    <mat-accordion class="tab-body odd-detail" multi>
        <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
            *ngFor="let market of toWinTossMarket;trackBy: itemTrackBy;let mDTIndex=index;" (click)="matchDetail(market.eventId)"
            (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                    <div fxLayout="row">
                        
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                            <span class="market-title">
                                <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                                <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                                {{market.eventName}}
                            </span>
                        </div>
                    </div>
                    
                </mat-panel-title>
                
            <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
                *ngFor="let runner of market.marketBook.runners; let i = index;">
                <label fxLayoutAlign="start left" fxFlex="70%"
                    style="padding-left: 10px;">
                    <span>{{searchRunner(market?.runners,runner?.selectionId)}}</span>
                   
                    <span class="text-bold" [ngClass]="{'red':calProLoss(market.runners,market.marketBook,i,market)<0,'green':calProLoss(market.runners,market.marketBook,i,market)>=0}">
                        {{calProLoss(market?.runners,market?.marketBook,i,market)| number : '0.0-2'}}
                    </span>
                </label>
                <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                    fxFlex="17%" *ngIf="runner?.availableToBack">
                    <span class="odds-value">{{(runner?.availableToBack
                        !=undefined?runner?.availableToBack?.price :'')}}</span>
                    <span class="odds-small-value">{{(runner?.availableToBack
                        !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
                </button>
                <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                    fxFlex="17%" *ngIf="!runner?.availableToBack">
                    <span class="odds-value">-</span>
                    <span class="odds-small-value">-</span>
                </button>
                <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                    fxFlex="17%" *ngIf="runner?.availableToLay">
                    <span class="odds-value">{{(runner?.availableToLay
                        !=undefined?runner?.availableToLay?.price :'')}}</span>
                    <span class="odds-small-value">{{(runner?.availableToLay
                        !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
                </button>
                <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                    fxFlex="17%" *ngIf="!runner?.availableToLay">
                    <span class="odds-value">-</span>
                    <span class="odds-small-value">-</span>
                </button>
    
            </div>
            
        </mat-expansion-panel>
    
    </mat-accordion>
  </ng-container>
  

 <!-- Bookmaker -->
 <ng-container *ngIf="bookmakerMarket?.length>0">
    <p id="sep_line"><span>Bookmaker</span></p>
    <mat-accordion class="tab-body odd-detail" multi>
       <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
           *ngFor="let market of bookmakerMarket;trackBy: itemTrackBy;let mDTIndex=index;" (click)="matchDetail(market.eventId)"
           (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
               <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                   <div fxLayout="row">
                       
                       <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                           <span class="market-title">
                               <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                               <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                               {{market.eventName}}
                           </span>
                           <span style="display:flex;float:right">bookmaker</span>
                       </div>
                   </div>
                   
               </mat-panel-title>
               
           <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%"
               *ngFor="let runner of market.marketBook.runners; let i = index;">
               <label fxLayoutAlign="start left" fxFlex="70%"
                   style="padding-left: 10px;">
                   <span>{{searchRunner(market?.runners,runner?.selectionId)}}</span>
                  
                   <span class="text-bold" [ngClass]="{'red':calProLoss(market.runners,market.marketBook,i,market)<0,'green':calProLoss(market.runners,market.marketBook,i,market)>=0}">
                       {{calProLoss(market?.runners,market?.marketBook,i,market)| number : '0.0-2'}}
                   </span>
               </label>
               <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                   fxFlex="17%" *ngIf="runner?.availableToBack">
                   <span class="odds-value">{{(runner?.availableToBack
                       !=undefined?runner?.availableToBack?.price :'')}}</span>
                   <span class="odds-small-value">{{(runner?.availableToBack
                       !=undefined?(runner?.availableToBack?.size ) :'')}}</span>
               </button>
               <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                   fxFlex="17%" *ngIf="!runner?.availableToBack">
                   <span class="odds-value">-</span>
                   <span class="odds-small-value">-</span>
               </button>
               <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                   fxFlex="17%" *ngIf="runner?.availableToLay">
                   <span class="odds-value">{{(runner?.availableToLay
                       !=undefined?runner?.availableToLay?.price :'')}}</span>
                   <span class="odds-small-value">{{(runner?.availableToLay
                       !=undefined?(runner?.availableToLay?.size ) :'')}}</span>
               </button>
               <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                   fxFlex="17%" *ngIf="!runner?.availableToLay">
                   <span class="odds-value">-</span>
                   <span class="odds-small-value">-</span>
               </button>
   
           </div>
           
       </mat-expansion-panel>
   
   </mat-accordion>
 </ng-container>
 
  <!-- Session -->
  <ng-container *ngIf="sessionMarket?.length>0">
    <p id="sep_line"><span>Session</span></p>
  <mat-accordion *ngFor="let runner of sessionMarket;trackBy: itemTrackBy;let mDTIndex=index;" class="tab-body odd-detail" multi>
      <mat-expansion-panel [expanded]="true" hideToggle style="margin-top: 10px;"
          (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false" (click)="matchDetail(runner.eventId)">
              <mat-panel-title fxLayout="row" fxLayoutAlign="space-between center" class="panel-title">
                  <div fxLayout="row">
                      
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                          <span class="market-title">
                              <mat-icon *ngIf="!panelOpenState1">expand_less</mat-icon>
                              <mat-icon *ngIf="panelOpenState1">expand_more</mat-icon>
                              {{runner.eventName}}
                          </span>
                          
                      </div>
                  </div>
          
              </mat-panel-title>

          <div class="bottom-border" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
              <label fxLayoutAlign="start left" fxFlex="70%"
                  style="padding-left: 10px;">
                  <span>{{runner.marketName}}</span>
              </label>
              <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                  fxFlex="17%" *ngIf="runner?.marketBook?.availableToLay">
                  <span class="odds-value">{{(runner?.marketBook?.availableToLay
                      !=undefined?runner?.marketBook?.availableToLay?.price :'')}}</span>
                  <span class="odds-small-value">{{(runner?.marketBook?.availableToLay
                      !=undefined?(runner?.marketBook?.availableToLay?.size ) :'')}}</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                  fxFlex="17%" *ngIf="!runner?.marketBook?.availableToLay">
                  <span class="odds-value">-</span>
                  <span class="odds-small-value">-</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="back-button"
                  fxFlex="17%" *ngIf="runner?.marketBook?.availableToBack">
                  <span class="odds-value">{{(runner?.marketBook?.availableToBack
                      !=undefined?runner?.marketBook?.availableToBack?.price :'')}}</span>
                  <span class="odds-small-value">{{(runner?.marketBook?.availableToBack
                      !=undefined?(runner?.marketBook?.availableToBack?.size ) :'')}}</span>
              </button>
              <button fxLayout="column" fxLayoutAlign="center center" class="lay-button"
                  fxFlex="17%" *ngIf="!runner?.marketBook?.availableToBack">
                  <span class="odds-value">-</span>
                  <span class="odds-small-value">-</span>
              </button>
 
          </div>
     
      </mat-expansion-panel>

  </mat-accordion>
 </ng-container>

      </mat-card>
  </div>
    

<app-footer></app-footer>
</mat-sidenav-content>
</mat-sidenav-container>
