import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef, Inject, OnDestroy, AfterContentChecked } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BetSlipBottomSheetComponent } from '../bet-slip-bottom-sheet/bet-slip-bottom-sheet.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'q';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Match } from '../model/match';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from '../services/users.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from '../services/login.service';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss']
})
export class MatchDetailComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('accordion') accordion: MatAccordion;
  manualMatchStatus: boolean = true;
  bookmakerStatus: string;
  netConnectService: Subscription;
  isConnected: any;
  eventId: any;
  matchoddMarket: any = [];
  toWinTossMarkt: any = [];
  bookmakerMarket: any = [];
  sessionMarket: any = [];
  matchDetail: any;
  marketName: any;
  lodTimer: number = 0;
  clsInterval: any;
  betSub: any;
  internetConn: boolean = true;
  panelOpenState1: any = true;
  panelOpenState2: any;
  panelOpenState3: any;
  matchData: any;
  match_id: any;
  matchName: any;
  matchDate: any;
  isSocket: number;
  userDetails: any;
  score: boolean;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  tv: boolean;
  graph: boolean;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  matchDetailLength: any;
  callingType = 1;
  matchCalling = 1;
  timer: any;
  fancytimer: any
  fancy: any;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  perball: any;
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  matchLength: any;
  callingFancyType = 1;
  inplayStatus: any;
  teamPositionData: any;
  selectedAllBetFilter: any = 'all';
  selectedFancyBetFilter: any = 'all';
  selectedMatchBetFilter: any = 'all';
  allBetData: any;
  moment: any = moment;
  showFancyList: boolean;
  userData: {};
  firstData: any;
  homeData: any;
  modalRef: BsModalRef;
  allBetDataLength: number = 0;
  matchedBetsLength: any;
  fancyBetsLength: any;
  fancyPosData: any;
  fancyLiability: any;
  betSize: any;
  selectedMarket: any
  selectedFancyMarket: any;
  marketIds: any = [];
  marketRunnerData: any;
  twenty: boolean;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  applyUserValidation: boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyIdValidation: any;
  fancyLength: any;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ringOn: boolean = false;
  param: any;
  betDataPopup: boolean;
  allPopBetData: any;
  message: string;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  subscription: Subscription;
  sessionMarketExposure: any;
  sessionrunnerProfit: any;
  videourl: any;
  isAndroid: any;
  isiPhone: any;
  iphone: any;
  filterdata: any;
  // matcheventId: any;
  getdata: any;
  eventTypeId: any;
  constructor(private _bottomSheet: MatBottomSheet, private http: HttpClient, private route: ActivatedRoute, public sanitizer: DomSanitizer, private router: Router, public toastr: ToastrService,
    public matchModel: Match, private modalService: BsModalService, private cdref: ChangeDetectorRef, private usersService: UsersService, public dialog: MatDialog, private loginService: LoginService, private socket: Socket, private connectionService: ConnectionService) {
    this.route.paramMap.subscribe(param => {
      this.eventId = param.get('eventId') // (+)Converts string 'id' to number
    });

    this.subscription = this.usersService.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
        this.getMyBets();
        // this.subscription.unsubscribe();
      });
    this.eventTypeId = sessionStorage.getItem('eventTypeId')

    this.sessionMarketExposure = {};

    // this.betSub = this.usersService.returnBetStatus().subscribe
    // (data => { //message contains the data sent from service
    //   if (data == 'Match Odds' || data == 'To Win Toss') {
    //     this.lodTimer = 5;
    //   }
    //   else if (data == 'Bookmaker') {
    //     this.lodTimer = 2;
    //   } else {
    //     this.lodTimer = 3;
    //   }
    //   this.setIntLod();
    //   // this.betSub.unsubscribe();

    // });

    this.checkIntConn();
    this.getloaderdata();
  }

  ngOnInit() {
    this.createRoom();
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isAndroid = /android/.test(userAgent);
    this.isiPhone = /iphone|ipad|ipod/.test(userAgent);
  }

  getloaderdata() {
    this.usersService.Get("system-setting").subscribe((result: any) => {
      console.log(result);
      this.getdata = result.data;
      this.getloadtimer()
    });
  }

  getloadtimer() {
    this.filterdata = this.getdata.filter(a => { return a.eventType.id == this.eventTypeId });
    // console.log(this.filterdata);
    // console.log(this.filterdata[0].eventType.id);
    if (this.filterdata[0].eventType.id === "4") {
      this.betSub = this.usersService.returnBetStatus().subscribe
        (data => { //message contains the data sent from service
          // console.log(data);
          
          if (data == 'Match Odds' || data == 'To Win Toss') {
            var time = (this.filterdata[0].betDelay / 1000);
            console.log(time);
            this.lodTimer = time;
          }  else if (data == 'Bookmaker') {
            let time = (this.filterdata[0].bookmakerDelay);
            this.lodTimer = time;
          } else if (data == 'TO Win Toss') {
            var time = (this.filterdata[0].betDelay / 1000);
            // console.log(time);
            this.lodTimer = time;
          }  else {
            let time = (this.filterdata[0].sessionDelay/1000);
            this.lodTimer = time;
            // console.log(this.lodTimer);
            
          }
          this.setIntLod();
          // this.betSub.unsubscribe();

        });

    } else {
      this.betSub = this.usersService.returnBetStatus().subscribe
        (data => { //message contains the data sent from service
          if (data) {
            var time = this.filterdata[0].betDelay / 1000;
            // console.log(time);
            this.lodTimer = time;
          }
          this.setIntLod();
        });

    }

  }

  checkIntConn() {

    // checkInternetConnection

    this.netConnectService = this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.internetConn = true;
      }
      else {
        this.internetConn = false;
      }
    })
    // console.warn('checkConn');

    // this.socket.on('disconnect',(function(data:any){
    //   if(data){
    //     console.warn('disconnect');
    //     this.internetConn=false;
    //   }
    //  }).bind(this));

    //   this.socket.on('connect',(function(data:any){
    //       console.warn('connect');
    //       this.internetConn=true;  
    //  }).bind(this)); 
  }

  setIntLod() {
    this.clsInterval = setInterval(() => { this.decValue(); }, 1000);
  }

  decValue() {
    this.lodTimer--;
    if (this.lodTimer == 0) {
      clearInterval(this.clsInterval);
    }
  }


  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }

  async createRoom() {

    this.userDetails = await this.getDetials();
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      eventId: this.eventId,
    };

    this.socket.emit('add-to-room', data);

    this.socket.on('event-pulse-' + this.eventId, (function (data: any) {
      // console.warn(data);

      const eventData = data;
      this.matchDetail = data;
      this.matchoddMarket = [];
      this.bookmakerMarket = [];
      this.sessionMarket = [];
      this.toWinTossMarket = [];

      for (var i = 0; i < eventData.length; i++) {
        if (eventData[i].marketType === 'SESSION') {
          this.sessionMarket.push(eventData[i]);
          //  console.warn(this.sessionMarket);

        }
        else if (eventData[i].marketType === 'Special') {
          this.bookmakerMarket.push(eventData[i]);
        }
        else if (eventData[i].marketName === 'TO Win Toss') {
          this.toWinTossMarket.push(eventData[i]);
        }
        else {
          this.matchoddMarket.push(eventData[i]);
          // console.log( this.matchoddMarket);

        }

        //  tv

        if (this.matchDetail[i].url) {
          // console.log('url');

          if (!this.liveUrl) {

            if (this.checkInplayStatus(this.matchDetail[i].openDate)) {

              // this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.matchDetail[i].url);
              // this.videourl = this.matchDetail[i].url;
              if (this.isiPhone) {
                // alert('iphone')
                this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.matchDetail[i].iphone);
                this.videourl = this.matchDetail[i].iphone;
              } else {
                // alert('android')
                this.liveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.matchDetail[i].url);
                this.videourl = this.matchDetail[i].url;
              }
            }

          }
        }

        if (this.matchDetail[i].score) {
          // console.log('score');
          if (!this.graphicTvUrl) {
            // console.log('score');
            this.graphicTvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.matchDetail[i].score);
            if (!this.liveUrl) {
              document.getElementById('scoreurl').click();
            }
          }
        }

      }

      // check STatus
      if (this.matchoddMarket[0].competitionName === 'Others') {
        this.manualMatchStatus = false;
      } else if (this.matchoddMarket[0].marketTypeStatus === "undefined") {
        this.manualMatchStatus = true;
      }

      this.marketName = eventData[0].marketName;
      this.matchName = eventData[0].eventName;
      this.matchDate = eventData[0].openDate;

    }).bind(this));

    this.getMyBets();
    // setTimeout(() => {
    //   this.getloadtimer(this.this.eventTypeId)
    // }, 2000);

  }


  checkInplayStatus(value): boolean {
    let CurrentDate = new Date();
    const currDate = CurrentDate.toISOString()
    if (currDate > value) {
      return true;
    }
    else {
      return false;
    }

  }

  checkMatchOddStatus(matchOddsData: any, runner: any): boolean {

    if (matchOddsData.marketBook.status === 'OPEN' && runner.status === 'ACTIVE' &&
      matchOddsData.managerStatus[this.userDetails.details.manager] === true) {
      return false;
    }
    else {
      return true;
    }


    // if(matchOddsData.marketBook.status ==='OPEN' && matchOddsData.betStatus === false &&
    // matchOddsData.managerStatus[this.userDetails.details.manager] === true)
    // {
    //   return false;
    // }
    // else{
    //   return true;
    // }

  }

  checkWinTossStatus(matchOddsData: any): boolean {

    if (matchOddsData?.marketBook?.status === 'OPEN') {
      return false;
    }
    else {
      return true;
    }

  }

  checkBookmakerStatus(matchOddsData: any, runner: any): boolean {
    if (matchOddsData.marketBook.status === 'OPEN' && runner.status === 'ACTIVE' &&
      matchOddsData.managerStatus[this.userDetails.details.manager] === true) {
      return false;
    }
    else {
      return true;
    }

  }


  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  getOddsValue(matchOddsData, price, selectionId, marketid, marketName, eventName, type, runnerName, amount) {

    this.betslipinfo = true;
    this.betPrice = price;
    const betdataS = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
          manager: this.userDetails.details.manager,
          master: this.userDetails.details.master,
          subadmin: this.userDetails.details.subadmin,
          image: this.userDetails.details.image,
          admin: this.userDetails.details.admin,
          balance: this.userDetails.details.balance,
          exposure: this.userDetails.details.exposure,
        }
      },
      bet: {
        runnerId: selectionId,
        selectionName: runnerName,
        rate: price,
        stake: 0,
        marketId: marketid,
        marketName: marketName,
        eventId: this.eventId,
        eventName: eventName,
        type: type,
      }
    };

    // betBottemShetOpenford
    this.arrayObj = {
      matchOddsData: matchOddsData,
      allBetData: this.allBetData,
      selection_id: selectionId,
      market_id: marketid,
      odds: price,
      stake: Math.round(amount),
      is_back: type,
      is_fancy: 0,
      MatchName: marketName,
      placeName: '',
      isManual: 0,
      is_session_fancy: 'N'
    };

    // localStorage.setItem('type',type);
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    sessionStorage.setItem('betDataS', JSON.stringify(betdataS));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  setSessionValue(price, marketid, marketName, eventName, type, size) {
    this.betslipinfo = true;
    this.betPrice = price;
    this.betSize = size;
    // this.fancyIdValidation = FancyObject.fancy_id;

    let newRate;
    if (size === 0) {
      newRate = 1;
    }
    else {
      newRate = size / 100;
    }

    const sessionBet = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
          manager: this.userDetails.details.manager,
          master: this.userDetails.details.master,
          subadmin: this.userDetails.details.subadmin,
          image: this.userDetails.details.image,
          admin: this.userDetails.details.admin,
          balance: this.userDetails.details.balance,
          exposure: this.userDetails.details.exposure,
        }
      },
      bet: {
        runnerId: 1,
        selectionName: price,
        rate: newRate,
        stake: 0,
        marketId: marketid,
        marketName: marketName,
        eventId: this.eventId,
        eventName: eventName,
        type: type,
      }

    }


    // betBottemShetOpenford
    this.arrayObj = {
      fancy_id: '',
      market_id: marketid,
      odds: price,
      stake: 0,
      is_fancy: 1,
      is_back: type,
      MatchName: '',
      placeName: '',
      isManual: 0,
      size: size,
      is_session_fancy: 'Y'
    };

    this.matchModel.isbetslipshow = true;
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    sessionStorage.setItem('betSessionData', JSON.stringify(sessionBet));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }



  getBetSlipDataForOdds() {

    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    // this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    // if (this.IsToggle) {
    //   this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
    //   this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    //   this.matchModel.loading1 = true;
    //   // this.Place_Order(this.matchModel.tempArray);
    // }
    this.openBetSlipBottomSheet(this.matchModel, this.arrayObj)
  }
  // session_book
  session_book(sessionBook: TemplateRef<any>, market: any) {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        }
      },
      market: market,
    };

    this.socket.emit('get-runner-profit', data);

    this.socket.on('get-runner-profit-success', (function (data: any) {
      if (data) {

        this.sessionrunnerProfit = '';
        this.sessionrunnerProfit = data.runnerProfit;
        this.socket.removeListener('get-runner-profit-success');
        this.modalRef = this.modalService.show(
          sessionBook,
          Object.assign({}, { class: 'userBook-modal modal-md' })
        );
      }

    }).bind(this));
  }

  getbetSessionalue(bets: any, marketId: any) {
    var runnerProfit = {};
    var total = 0;
    var totalArr = [];
    var min = 0,
      max = 0,
      bc = 0;
    for (var j = 0; j < bets.length; j++) {
      if (j == 0) {
        min = parseInt(bets[j].selectionName);
        max = parseInt(bets[j].selectionName);
      } else {
        if (parseInt(bets[j].selectionName) > max) max = parseInt(bets[j].selectionName);
        if (parseInt(bets[j].selectionName) < min) min = parseInt(bets[j].selectionName);
      }
    }

    for (var i = min - 1; i < max + 1; i++) {
      var result = i;
      var c2 = 0,
        maxLoss = 0;
      for (var bi1 = 0; bi1 < bets.length; bi1++) {
        c2++;
        var b1 = bets[bi1];
        if (b1.type == 'Back') {
          if (result >= parseInt(bets[bi1].selectionName)) {
            maxLoss += Math.round(bets[bi1].rate * bets[bi1].stake);
          } else {
            maxLoss -= bets[bi1].stake;
          }
        } else {
          if (result < parseInt(bets[bi1].selectionName)) {
            maxLoss += bets[bi1].stake;
          } else {
            maxLoss -= Math.round(bets[bi1].rate * bets[bi1].stake);
          }
        }
        //console.log(maxLoss);
        //console.log(bets[bi1].username);
      }
      runnerProfit[i] = maxLoss;


    }
    //console.log(w);
    var w = null;
    if (w != null) {
      if (runnerProfit[w] == null) {
        runnerProfit[w] = 0;
      }
    }
    for (const t in runnerProfit) {

      totalArr.push(runnerProfit[t])
    }
    this.sessionMarketExposure[marketId] = Math.min.apply(Math, totalArr)    // 1;

    // console.log(totalArr);  
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn);

    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;

      }
      this.matchModel.calculateProfitLoss(back);
      if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.priceVal > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.priceVal * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }

      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  calculateP_lOnStack(stake, stkbtn, isback, back) {

    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  searchRunner(runners: any[], id: string): any {
    if (!runners) return null;
    for (var key in runners) {
      if (runners[key].selectionId == id)
        return runners[key].runnerName;
    }

  }

  searchRunnerse(runners: any[], id: string): any {
    if (!runners) return null;
    for (var key in runners) {
      if (runners[key].selectionId == id)
        return runners[key].runnerName;
    }

  }

  scoreApi() {
    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.http.get('https://ex.7dayexch.biz/api/v2/getLiveScore?id=' + this.matchData.match_id).subscribe((data) => {
      if (data['status'] == '1') {
        if (data["result"]["type"] == "up" || data["result"]["type"] == "auto") {

        } else {
          this.score = true;
          this.scoreData = data["result"]["home"];
          this.scoreLength = Object.keys(this.scoreData).length;
          if ((this.scoreLength) <= 31) {
            this.twenty = true;
            this.scoreBoard = {
              "b1s": (this.scoreData.b1s).split(','),
              "b2s": (this.scoreData.b2s).split(','),
              "bw": this.scoreData.bw,
              "i": this.scoreData.i,
              "i1": {
                "ov": this.scoreData.i1.ov,
                "sc": this.scoreData.i1.sc,
                "wk": this.scoreData.i1.wk
              },
              "i1b": this.scoreData.i1b,
              "i2": {
                "ov": this.scoreData.i2.ov,
                "sc": this.scoreData.i2.sc,
                "tr": this.scoreData.i2.tr,
                "wk": this.scoreData.i2.wk
              },
              "iov": this.scoreData.iov,
              "lw": this.scoreData.lw,
              "p1": this.scoreData.p1,
              "p2": this.scoreData.p2,
              "pb": (this.scoreData.pb).split(','),
              "pt": (this.scoreData.pt).split(','),
              "t1": {
                "f": this.scoreData.t1.f,
                "ic": this.scoreData.t1.ic,
                "n": this.scoreData.t1.n
              },
              "t2": {
                "f": this.scoreData.t2.f,
                "ic": this.scoreData.t2.ic,
                "n": this.scoreData.t2.n
              }
            }
            this.perball = (this.scoreBoard.pb).slice(1).slice(-6);
          } else {
            this.twenty = false;
            this.scoreBoard = {
              "b1s": (this.scoreData.b1s).split(','),
              "b2s": (this.scoreData.b2s).split(','),
              "bw": this.scoreData.bw,
              "i": this.scoreData.i,
              "i1": {
                "ov": this.scoreData.i1.ov,
                "sc": this.scoreData.i1.sc,
                "wk": this.scoreData.i1.wk
              },
              "i1b": this.scoreData.i1b,
              "i3b": this.scoreData.i3b,
              "i2": {
                "ov": this.scoreData.i2.ov,
                "sc": this.scoreData.i2.sc,
                "tr": this.scoreData.i2.tr,
                "wk": this.scoreData.i2.wk
              },
              "i3": {
                "ov": this.scoreData.i3.ov,
                "sc": this.scoreData.i3.sc,
                "tr": this.scoreData.i3.tr,
                "wk": this.scoreData.i3.wk
              },
              "i4": {
                "ov": this.scoreData.i4.ov,
                "sc": this.scoreData.i4.sc,
                "tr": this.scoreData.i4.tr,
                "wk": this.scoreData.i4.wk
              },
              "iov": this.scoreData.iov,
              "lw": this.scoreData.lw,
              "p1": this.scoreData.p1,
              "p2": this.scoreData.p2,
              "pb": (this.scoreData.pb).split(','),
              "pt": (this.scoreData.pt).split(','),
              "t1": {
                "f": this.scoreData.t1.f,
                "ic": this.scoreData.t1.ic,
                "n": this.scoreData.t1.n
              },
              "t2": {
                "f": this.scoreData.t2.f,
                "ic": this.scoreData.t2.ic,
                "n": this.scoreData.t2.n
              }
            }

            this.perball = (this.scoreBoard.pb).slice(1).slice(-6);
          }

        }
      }
    }, (err) => {
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          this.timer = setTimeout(() => resolve(this.scoreApi()), 3000);
        }
      })
  }
  betslipStatus: boolean = false;
  hideClose() {
    this.betslipStatus = false;
  }
  activeBetslip() {
    this.betslipStatus = true;
  }
  liveTVStatus: boolean = true;
  openTvDiv() {
    this.liveTVStatus = !this.liveTVStatus;
  }
  liveScoreStatus: boolean = false;
  openScoreDiv() {
    this.liveScoreStatus = !this.liveScoreStatus;
  }
  back() {
    window.history.back();
  }
  openModalBets(bets: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      bets,
      Object.assign({}, { class: 'bets-modal modal-lg' })
    );
    this.filterallbetBasedRadioSelect(event, 'all')
  }

  openBetSlipBottomSheet(data, arrayObj): void {
    const BetSlipbottomSheetRef = this._bottomSheet.open(BetSlipBottomSheetComponent, {
      data: {
        matchModel: this.matchModel, arrayObj: this.arrayObj, betPrice: this.betPrice, betData: this.betData, betSize: this.betSize,
        applyUserValidation: this.applyUserValidation, sportsSettingValues: this.sportsSettingValues, checkEventLimit: this.check_event_limit, fancyId: this.fancyIdValidation,
        session_setting: this.sessionSetting, ring_On: this.ringOn
      },
    });

    // BetSlipbottomSheetRef.afterDismissed().subscribe(() => {
    //   this.getFancyLiability()
    // });
  }

  getMyBets() {
    this.allBetData = [];
    const getBet = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      filter: {
        eventId: this.eventId,
        username: this.userDetails.details.username,
        deleted: false,
        result: 'ACTIVE',
      },
      sort: { placedTime: -1 },
    };

    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function (data: any) {
      // console.warn(data);

      if (data.length > 0) {
        // console.log(data);
        if (data[0].eventId === this.eventId) {
          this.allBetDataLength = 0;
          this.allBetData = data;
          // console.warn(data);
          for (var i = 0; i < this.allBetData.length; i++) {
            if (this.allBetData[i].runnerId == 1) {
              var filterData = this.allBetData.filter((val) => { return this.allBetData[i].marketId == val.marketId });

              this.getbetSessionalue(filterData, this.allBetData[i].marketId);
              //this.sessionMarketExposure[this.allBetData[i].marketId]=filterData.map(item=>item.ratestake).reduce( (a,b)=> a+b);
            }
          }


          this.allBetDataLength = this.allBetData.length;

        }

        this.socket.removeAllListeners('get-bets-success');
      }
    }).bind(this));

  }

  calProLoss(a: any, data: any, index: number, matchOddsData: any) {

    if (a && this.allBetData) {
      let test = this.allBetData.filter(item => {
        return item.marketId == matchOddsData.marketId;
      });

      let betsValue = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId == a.selectionId;
        } else {
          return item.runnerId == data.runners[index].selectionId;
        }
      });

      let laystaketotal = test.filter(item => {
        if (a.selectionId != undefined) {
          return item.runnerId != a.selectionId;
        } else {
          return item.runnerId != data.runners[index].selectionId;
        }
      });

      let backData = betsValue.filter(item => {
        return item.type == 'Back';
      });

      let layData = betsValue.filter(item => {
        return item.type == 'Lay';
      });

      let oppBack = laystaketotal.filter(item => {
        return item.type == 'Back';
      });

      let totalOppBack = 0;
      oppBack.map(b => {
        totalOppBack = totalOppBack + b.stake;
      });

      let oppLay = laystaketotal.filter(item => {
        return item.type == 'Lay';
      });

      let totalOppLay = 0;
      oppLay.map(b => {
        totalOppLay = totalOppLay + b.stake;
      });


      let backvalue = 0;
      backData.map(b => {
        let back = b.stake * (b.rate - 1);
        backvalue = backvalue + back;
      });

      let layvalue = 0;
      layData.map(b => {
        let lay = b.stake * (b.rate - 1);
        layvalue = layvalue + lay;
      });

      let backtotal = backvalue - totalOppBack;
      let laytotal = totalOppLay - layvalue;

      let markettotal;
      //  if (market === true) 
      //  {
      //   let totalno = backtotal + laytotal;
      //   markettotal = totalno * 37;
      //  } 
      //  else 
      //  {
      //   markettotal = backtotal + laytotal;
      //  }

      markettotal = backtotal + laytotal;

      return (markettotal);
    }

  }


  volumeOn(i) {
    if (i == 0) {
      this.ringOn = false;
    } else {
      this.ringOn = true;
    }
  }

  filterallbetBasedRadioSelect(event, filterName) {
    this.allBetData = this.betData
    if (filterName == 'all') {
      this.allBetData = this.betData
    } else {
      this.allBetData = this.betData.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }

  video(videourl) {
    console.log(videourl);

    sessionStorage.setItem('url', videourl)
    this.router.navigate(["video"]);
  }

  openmodal(fullwidth: TemplateRef<any>, detailes) {
    // console.log(this.detaile);
    this.modalRef = this.modalService.show(
      fullwidth,
      Object.assign({}, { class: 'fullwidth modal-fullscreen' })
    );
  }

  filterFancybetBasedRadioSelect(event, filterName) {
    this.fancyBets = this.betData.filter(t => t.is_fancy == '1');
    if (filterName == 'all') {
      this.fancyBets = this.betData.filter(t => t.is_fancy == '1');
    } else {
      this.fancyBets = this.fancyBets.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }
  filterMatchbetBasedRadioSelect(event, filterName) {
    this.matchedBets = this.betData.filter(t => t.is_fancy == '0');
    if (filterName == 'all') {
      this.matchedBets = this.betData.filter(t => t.is_fancy == '0');
    } else {
      this.matchedBets = this.matchedBets.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }
  openModalCondition(Terms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      Terms,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  addOtherMarket(data) {
    localStorage.setItem('matchData', JSON.stringify(data));
    if (this.router.url.split('?')[0] == '/match-detail') {
      window.location.reload();
    } else {
      this.router.navigate(['match-detail']);
    }
  }

  itemTrackBy(index: number, item) {
    return index;
  }

  eventSocketRemove() {
    let newremovedata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      },
      eventId: this.eventId,
    };

    this.socket.emit('remove-from-room', newremovedata);
  }
  cashOut(market: any) {
    let greenAmount = 0;
    let redAmount = 0;
    let currentbackredodds = 0;
    let currentlaygrrenodds = 0;
    let runners;
    let runners_point = [];
    let runners_v = {};
    for (var i = 0; i < market.marketBook.runners.length; i++) {

      if (this.calProLoss(market?.runners, market?.marketBook, i, market) > 0) {
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, i, market);
        currentlaygrrenodds = market.marketBook.runners[i]?.availableToLay?.price;
        runners = market.marketBook.runners[i];
        runners_point.push(greenAmount)
      }
      else {
        redAmount = this.calProLoss(market?.runners, market?.marketBook, i, market);
        runners_point.push(redAmount);
        runners_v[i] = market.marketBook.runners[i];
        currentbackredodds = market.marketBook.runners[i]?.availableToBack?.price;

      }

    }

    let backStake = (greenAmount - redAmount) / currentbackredodds;
    let layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    if (!runners) {
      if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {


        return;
      }

      if (runners_point[0] > runners_point[1]) {
        runners = market.marketBook.runners[0];
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, 0, market);
        currentlaygrrenodds = market.marketBook.runners[0]?.availableToLay?.price;
        redAmount = this.calProLoss(market?.runners, market?.marketBook, 1, market);
      }
      else {
        runners = market.marketBook.runners[1];
        greenAmount = this.calProLoss(market?.runners, market?.marketBook, 1, market);
        currentlaygrrenodds = market.marketBook.runners[1]?.availableToLay?.price;
        redAmount = this.calProLoss(market?.runners, market?.marketBook, 0, market);
      }

    }


    if (layStake === Infinity) {
      layStake = (greenAmount - redAmount) / currentlaygrrenodds;
    }


    this.getOddsValue(market, (runners?.availableToLay?.price), runners?.selectionId, market?.marketId, market?.marketName, market?.eventName, 0, this.searchRunner(market?.runners, runners?.selectionId), layStake)

  }

  cashOut_value(market: any) {
    let runners_point = [];
    for (var i = 0; i < market.marketBook.runners.length; i++) {

      runners_point.push(this.calProLoss(market?.runners, market?.marketBook, i, market))
    }
    if (runners_point[0] === 0) { return 0 };
    if (Math.trunc(runners_point[0]) == Math.trunc(runners_point[1])) {
      return 0;
    }
    else {
      return 1;
    }

  }


  ngOnDestroy(): void {
    this.netConnectService.unsubscribe();
    this.eventSocketRemove();
    this.socket.removeAllListeners('');
  }

}



@Component({
  selector: 'fancy-bet-bottom-sheet',
  templateUrl: 'fancy-bet-bottom-sheet.html',
  styleUrls: ['./match-detail.component.scss']
})
export class FancyBetBottomSheet {
  fancyPosData: any;
  selectedFancyMarket: any;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<FancyBetBottomSheet>) {
    this.fancyPosData = this.data.data;
    this.selectedFancyMarket = this.data.fancy;
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }


}


@Component({
  selector: 'bet-list-popup',
  templateUrl: 'bet-list-popup.html',
})
export class BetListPopup {
  allPopBetData: any;
  selectedMarket: any;
  betData: any;
  selectedPopAllBetFilter: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<BetListPopup>,) {
    this.allPopBetData = this.data.betData,
      this.betData = this.data.betData,
      this.selectedMarket = this.data.selectedMarket
  }
  closePopup(): void {
    this.dialogRef.close();
  }
  filterPopupallbetBasedRadioSelect(event, filterName) {
    this.allPopBetData = this.betData
    if (filterName == 'all') {
      this.allPopBetData = this.betData
    } else if (filterName == '') {
      this.allPopBetData = this.betData
    } else {
      this.allPopBetData = this.betData.filter(function (object) {
        return object.is_back == filterName
      });
    }
  }



}