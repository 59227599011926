<div class="container">
<div class="row header text-light" >
    <div class="col-3 mt-3">
      <mat-icon [routerLink]="['/casino']"><i class="material-icons">arrow_back_ios</i></mat-icon>
    </div>
    <div class="col-9 text-end">
       <div class="addcard" data-toggle="modal" data-target="#exampleModalCenter" style="float:right ;text-align: center; margin-right: 17px; margin-top: 10px;">
           <small> <span class="font-weight-bold text-warning" style="font-size:15px;">Add Coins:</span> {{casinoBal}}</small>
      </div>
  
    </div>
  </div>
</div>  
<div style="height:103vh">
    <iframe class="sun" [src]="iframUrl" *ngIf="iframUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%" height="100%;" scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
</div>
<app-footer></app-footer>
    
  <!-- BalModal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Wallet</h5>
          <button type="button" id="closebutton" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container">
            <div class="row">
                <div class="col-12">
                  <h4 style="font-weight:500;">Note: <span>10 coins =  <span>1</span> coins in casino wallet</span></h4>
                </div>
              </div>
              <div class="row" style="width:100%;margin-top:20px;">
                <div class="col-2">
                  <img src="assets/images/walletIcon/main.png" width="30" height="30" alt="">
                </div>
                <div class="col-7" style="padding-top:6px;"><span >Main Balance</span></div>
                <div class="col-3" style="padding-top:6px;"><span>{{walletBalance}}</span></div>
              </div>
              <div class="row" style="width: 100%; margin-top: 20px;">
                <div class="col-2">
                  <img src="assets/images/walletIcon/casino.png" width="35" height="34" alt="">
                </div>
                <div class="col-7" style="padding-top:10px;"><span> Casino Balance </span></div>
                <div class="col-3" style="padding-top:10px;"><span>{{casinoBal | number : '0.0-2'}}</span></div>
              </div>
              <div class="row" style="margin-top: 20px;" >
                <div class="col-6" style="text-align:center ;">
                  <button class="btn-outline-success border border-success rounded"  (click)="action(1,'Deposit');" mat-stroked-button>DEPOSIT</button>
                </div>
                <div class="col-6" style="text-align:center ;">
                  <button class="btn-outline-danger border border-danger rounded"  (click)="action(2,'Withdraw');" mat-stroked-button>WITHDRAW</button>
                </div>
              </div>

              <div *ngIf="depWith" class="row mt-4" >
                <div class="col-12">
                     <p for="deposit_withdrow_label_id" >{{depWith}}</p>
                     <mat-form-field class="example-full-width" appearance="fill">
                       <mat-label>Amount</mat-label>
                       <input type="number" [(ngModel)]="amount" matInput>
                     </mat-form-field>
                     <button class="btn-outline-success border border-success rounded ml-3" [disabled]="loader" (click)="onSubmit();" mat-stroked-button>Submit</button>
                 </div>
             </div>
            </div>
        </div>
       
      </div>
    </div>
  </div>


