import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-casino-aura',
  templateUrl: './casino-aura.component.html',
  styleUrls: ['./casino-aura.component.scss']
})
export class CasinoAuraComponent implements OnInit {
  iframUrl:any;
  userDetails:any;
  isAndroid: any;
  isiPhone: any;
  constructor(private route: Router,public sanitizer :DomSanitizer) {
    this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
   }

  ngOnInit(): void {
    // var url = 'https://aura.fawk.app/'+this.userDetails.verifytoken+'/9601' ;
    // this.iframUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log(this.iframUrl);
    
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isAndroid = /android/.test(userAgent);
    this.isiPhone = /iphone|ipad|ipod/.test(userAgent);
    this.getAuraCasino();
    
  }

  getAuraCasino() {
    var casino_operatorId = '9601';
    if(this.isAndroid){
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://m2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId);
    }else if(this.isiPhone){
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://m2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId);
    }else{
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://d2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId);
    }

    var name = $('iframe[auracasino]').contents().find('TableId').val();
   console.log(name);
   
  }

}
